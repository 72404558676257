import {
  SET_USER,
  SET_ASSET_SEARCH_FORM_DATA,
  SET_ASSEMBLY_SEARCH_FORM_DATA,
  SET_PLANNING_SEARCH_FORM_DATA,
  SET_VENDOR_SEARCH_FORM_DATA,
  SET_PERMISSION_TYPES,
  SET_INVOICE_SEARCH_FORM_DATA,
  SET_CONTRACT_SEARCH_FORM_DATA,
  SET_ASSET_SUBJECT_FORM_DATA,
  SET_SELECT_FIELD_OPTIONS,
  SET_LOADING,
  SET_FILE_FORMAT_TYPES,
  SET_API_IS_BROKEN,
  TOGGLE_SHOW_MORE_ASSET_SEARCH_OPTIONS,
  TOGGLE_SHOW_MORE_PLANNING_SEARCH_OPTIONS,
  SET_AUTHENTICATING_WITH_MODAL,
  SET_ASSET_SEARCH_TABLE_CONFIG_PAGE_INDEX,
  SET_ASSET_SEARCH_TABLE_CONFIG_PAGE_SIZE,
  SET_PLANNING_SEARCH_TABLE_CONFIG_PAGE_INDEX,
  SET_PLANNING_SEARCH_TABLE_CONFIG_PAGE_SIZE,
  SET_RENDITION_TABLE_CONFIG_PAGE_INDEX,
  SET_RENDITION_TABLE_CONFIG_PAGE_SIZE,
  SET_ASSEMBLY_SEARCH_TABLE_CONFIG_PAGE_INDEX,
  SET_ASSEMBLY_SEARCH_TABLE_CONFIG_PAGE_SIZE,
  SET_INVOICE_SEARCH_TABLE_CONFIG_PAGE_INDEX,
  SET_INVOICE_SEARCH_TABLE_CONFIG_PAGE_SIZE,
  SET_ACTIVE_TAB,
} from 'store/action-types';

const reducer = (state = {}, action) => {
  if (typeof action === 'undefined') return state;

  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: action.user,
      };
    case SET_ASSET_SEARCH_FORM_DATA:
      return {
        ...state,
        assetSearchFormData: action.assetSearchFormData,
      };
    case SET_ASSEMBLY_SEARCH_FORM_DATA:
      return {
        ...state,
        assemblySearchFormData: action.assemblySearchFormData,
      };
    case SET_PLANNING_SEARCH_FORM_DATA:
      return {
        ...state,
        planningSearchFormData: action.planningSearchFormData,
      };
    case SET_VENDOR_SEARCH_FORM_DATA:
      return {
        ...state,
        vendorSearchFormData: action.vendorSearchFormData,
      };
    case SET_INVOICE_SEARCH_FORM_DATA:
      return {
        ...state,
        invoiceSearchFormData: action.invoiceSearchFormData,
      };
    case SET_CONTRACT_SEARCH_FORM_DATA:
      return {
        ...state,
        contractSearchFormData: action.contractSearchFormData,
      };
    case SET_ASSET_SUBJECT_FORM_DATA:
      return {
        ...state,
        assetSubjectFormData: action.assetSubjectFormData,
      };
    case SET_PERMISSION_TYPES:
      return {
        ...state,
        permissionTypes: action.permissionTypes,
      };
    case SET_SELECT_FIELD_OPTIONS:
      return {
        ...state,
        [action.pluralSelector]: action.options,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case SET_FILE_FORMAT_TYPES:
      return {
        ...state,
        fileFormatTypes: action.fileFormatTypes,
      };
    case SET_API_IS_BROKEN:
      return {
        ...state,
        apiIsBroken: action.apiIsBroken,
      };
    case TOGGLE_SHOW_MORE_ASSET_SEARCH_OPTIONS:
      return {
        ...state,
        showMoreAssetSearchOptions: !state.showMoreAssetSearchOptions,
      };
    case TOGGLE_SHOW_MORE_PLANNING_SEARCH_OPTIONS:
      return {
        ...state,
        showMorePlanningSearchOptions: !state.showMorePlanningSearchOptions,
      };
    case SET_ASSET_SEARCH_TABLE_CONFIG_PAGE_INDEX:
      return {
        ...state,
        assetSearchTablePageIndex: action.assetSearchTablePageIndex,
      };
    case SET_ASSET_SEARCH_TABLE_CONFIG_PAGE_SIZE:
      return {
        ...state,
        assetSearchTablePageSize: action.assetSearchTablePageSize,
      };
    case SET_PLANNING_SEARCH_TABLE_CONFIG_PAGE_INDEX:
      return {
        ...state,
        planningSearchTablePageIndex: action.planningSearchTablePageIndex,
      };
    case SET_PLANNING_SEARCH_TABLE_CONFIG_PAGE_SIZE:
      return {
        ...state,
        planningSearchTablePageSize: action.planningSearchTablePageSize,
      };
    case SET_RENDITION_TABLE_CONFIG_PAGE_INDEX:
      return {
        ...state,
        renditionTablePageIndex: action.renditionTablePageIndex,
      };
    case SET_RENDITION_TABLE_CONFIG_PAGE_SIZE:
      return {
        ...state,
        renditionTablePageSize: action.renditionTablePageSize,
      };
    case SET_ASSEMBLY_SEARCH_TABLE_CONFIG_PAGE_INDEX:
      return {
        ...state,
        assemblySearchTablePageIndex: action.assemblySearchTablePageIndex,
      };
    case SET_ASSEMBLY_SEARCH_TABLE_CONFIG_PAGE_SIZE:
      return {
        ...state,
        assemblySearchTablePageSize: action.assemblySearchTablePageSize,
      };
    case SET_INVOICE_SEARCH_TABLE_CONFIG_PAGE_INDEX:
      return {
        ...state,
        invoiceSearchTablePageIndex: action.invoiceSearchTablePageIndex,
      };
    case SET_INVOICE_SEARCH_TABLE_CONFIG_PAGE_SIZE:
      return {
        ...state,
        invoiceSearchTablePageSize: action.invoiceSearchTablePageSize,
      };
    case SET_AUTHENTICATING_WITH_MODAL:
      return {
        ...state,
        authenticatingWithModal: action.authenticatingWithModal,
      };
    case SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.activeTab,
      };
    default:
      return state;
  }
};

export default reducer;
