import React from 'react';
import PropTypes from 'prop-types';
import { useSortBy, useTable, usePagination, useFilters } from 'react-table';

import { API_MAX_PER_PAGE } from 'shared/constants/constants';
import DefaultTable from 'components/DefaultTable/DefaultTable';
import DefaultTableControls from 'components/DefaultTableControls/DefaultTableControls';
import stringFilterType from 'components/DefaultTable/DefaultTable.utils';
import userDetailTableColumns from './UserDetailTable.columns';

const UserDetailTable = ({ rowsData }) => {
  const numOfRows = rowsData.length;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      data: rowsData,
      columns: userDetailTableColumns,
      filterTypes: stringFilterType,
      manualPagination: false,
    },
    useFilters,
    useSortBy,
    usePagination
  );

  return (
    <div>
      {numOfRows < API_MAX_PER_PAGE ? (
        <p>
          Groups found: <strong>{numOfRows}</strong>
        </p>
      ) : (
        <p>
          Groups found: <strong>{API_MAX_PER_PAGE}</strong> (API max), from <strong>{numOfRows}</strong>.
        </p>
      )}
      <DefaultTableControls
        previousPage={previousPage}
        canPreviousPage={canPreviousPage}
        gotoPage={gotoPage}
        nextPage={nextPage}
        canNextPage={canNextPage}
        pageCount={pageCount}
        pageIndex={pageIndex}
        numOfPages={pageOptions.length}
        pageSize={pageSize}
        setPageSize={setPageSize}
        numOfRows={numOfRows}
      />
      <DefaultTable
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        page={page}
        prepareRow={prepareRow}
      />
      <DefaultTableControls
        previousPage={previousPage}
        canPreviousPage={canPreviousPage}
        gotoPage={gotoPage}
        nextPage={nextPage}
        canNextPage={canNextPage}
        pageCount={pageCount}
        pageIndex={pageIndex}
        numOfPages={pageOptions.length}
        pageSize={pageSize}
        setPageSize={setPageSize}
        numOfRows={numOfRows}
      />
    </div>
  );
};

UserDetailTable.defaultProps = {
  rowsData: [],
};

UserDetailTable.propTypes = {
  rowsData: PropTypes.arrayOf(
    PropTypes.shape({
      groupId: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    })
  ),
};

export default UserDetailTable;
