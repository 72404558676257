export const SET_USER = 'SET_USER';
export const SET_ASSET_SEARCH_FORM_DATA = 'SET_ASSET_SEARCH_FORM_DATA';
export const SET_VENDOR_ID_ASSET_SEARCH_FORM_DATA = 'SET_VENDOR_ID_ASSET_SEARCH_FORM_DATA';
export const SET_ASSEMBLY_SEARCH_FORM_DATA = 'SET_ASSEMBLY_SEARCH_FORM_DATA';
export const SET_PERMISSION_TYPES = 'SET_PERMISSION_TYPES';
export const SET_PLANNING_SEARCH_FORM_DATA = 'SET_PLANNING_SEARCH_FORM_DATA';
export const SET_VENDOR_SEARCH_FORM_DATA = 'SET_VENDOR_SEARCH_FORM_DATA';
export const SET_INVOICE_SEARCH_FORM_DATA = 'SET_INVOICE_SEARCH_FORM_DATA';
export const SET_CONTRACT_SEARCH_FORM_DATA = 'SET_CONTRACT_SEARCH_FORM_DATA';
export const SET_ASSET_SUBJECT_FORM_DATA = 'SET_ASSET_SUBJECT_FORM_DATA';
export const SET_ASSET_SUBJECT_RESULTS = 'SET_ASSET_SUBJECT_RESULTS';
export const SET_SELECT_FIELD_OPTIONS = 'SET_SELECT_FIELD_OPTIONS';
export const SET_LOADING = 'SET_LOADING';
export const SET_FILE_FORMAT_TYPES = 'SET_FILE_FORMAT_TYPES';
export const SET_API_IS_BROKEN = 'SET_API_IS_BROKEN';
export const TOGGLE_SHOW_MORE_ASSET_SEARCH_OPTIONS = 'TOGGLE_SHOW_MORE_ASSET_SEARCH_OPTIONS';
export const TOGGLE_SHOW_MORE_PLANNING_SEARCH_OPTIONS = 'TOGGLE_SHOW_MORE_PLANNING_SEARCH_OPTIONS';
export const SET_AUTHENTICATING_WITH_MODAL = 'SET_AUTHENTICATING_WITH_MODAL';
export const SET_ASSET_SEARCH_TABLE_CONFIG_PAGE_INDEX = 'SET_ASSET_SEARCH_TABLE_CONFIG_PAGE_INDEX';
export const SET_ASSET_SEARCH_TABLE_CONFIG_PAGE_SIZE = 'SET_ASSET_SEARCH_TABLE_CONFIG_PAGE_SIZE';
export const SET_PLANNING_SEARCH_TABLE_CONFIG_PAGE_SIZE = 'SET_PLANNING_SEARCH_TABLE_CONFIG_PAGE_SIZE';
export const SET_PLANNING_SEARCH_TABLE_CONFIG_PAGE_INDEX = 'SET_PLANNING_SEARCH_TABLE_CONFIG_PAGE_INDEX';
export const SET_RENDITION_TABLE_CONFIG_PAGE_SIZE = 'SET_RENDITION_TABLE_CONFIG_PAGE_SIZE';
export const SET_RENDITION_TABLE_CONFIG_PAGE_INDEX = 'SET_RENDITION_TABLE_CONFIG_PAGE_INDEX';
export const SET_ASSEMBLY_SEARCH_TABLE_CONFIG_PAGE_INDEX = 'SET_ASSEMBLY_SEARCH_TABLE_CONFIG_PAGE_INDEX';
export const SET_ASSEMBLY_SEARCH_TABLE_CONFIG_PAGE_SIZE = 'SET_ASSEMBLY_SEARCH_TABLE_CONFIG_PAGE_SIZE';
export const SET_INVOICE_SEARCH_TABLE_CONFIG_PAGE_INDEX = 'SET_INVOICE_SEARCH_TABLE_CONFIG_PAGE_INDEX';
export const SET_INVOICE_SEARCH_TABLE_CONFIG_PAGE_SIZE = 'SET_INVOICE_SEARCH_TABLE_CONFIG_PAGE_SIZE';
export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB';
